<script>
    /**
     * name：
     * user：sa0ChunLuyu
     * date：2022-03-10 19:34:15
     */
    export default {
        data() {
            return {}
        },
        props: {
            // 菜单按钮
            menu: {
                type: Array,
                default: []
            },
            // 单位信息
            info: {
                type: Object,
                default: null
            },
        },
        mounted() {
        },
        methods: {
            miClick(mi) {
                mi.func({
                    id: this.info.id,
                    name: this.info.name,
                    type: this.info.type,
                    content: this.info.content
                })
            },
            checkShow(mi) {
                if (mi.show === -1) return true
                if (mi.show === this.info.type) {
                    if (typeof mi.c_show === 'undefined') {
                        return true
                    } else {
                        if (!this.info.children.length) {
                            return true
                        } else {
                            if (this.info.children[0].type === mi.c_show) return true
                        }
                    }
                }
                return false
            }
        }
    }
</script>
<template>
    <div>
        <div class="lmenu_wrapper">
            <div v-if="checkShow(mi)" v-for="(mi,mk) in menu" @click="miClick(mi)">
                <div class="menu_item_wrapper">{{ mi.name }}</div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .menu_item_wrapper{
        padding: 3px 10px;
        }

    .menu_item_wrapper:hover{
        background: #666666;
        }

    .lmenu_wrapper{
        overflow: hidden;
        width: 120px;
        background: #404040;
        color: #ffffff;
        border: 1px silver solid;
        border-radius: 6px;
        position: absolute;
        right: 10px;
        z-index: 1;
        transform: translateX(100%);
        line-height: 30px;
        }
</style>
