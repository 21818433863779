<template>
    <div>
        <a-row>
            <a-col :span="24" style="margin-bottom: -20px;">
                <a-space style="margin-top: 10px; background: #ebf8fe; border: none" class="btner">
                    <div class="btnerlist"
                         @click="showDrawerNewItem({id:0, moban_content:'',moban_name:'', notice_type_ids:[]})">
                        <i class="iconfont icon-jia vm fcadd" style="vertical-align: -1px;"></i> 新建消息模板
                    </div>
                    <div class="btnerlist" @click="showDrawerEditItem()">
                        <i class="iconfont icon-lurubaogao1" style="vertical-align: -1px;"></i> 编辑消息模板
                    </div>
                    <div class="btnerlist" @click="setThis()">
                        <i class="iconfont icon-rizhi1" style="vertical-align: -1px;"></i> 设置默认模板
                    </div>
                    <div class="btnerlist" @click="delClick()">
                        <i class="iconfont icon-lajitong1" style="vertical-align: -1px;"></i> 删除模板
                    </div>
                </a-space>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="24">
                <table class="layui-table" lay-size="sm">
                    <thead>
                    <tr>
                        <th style="width: 2%"></th>
                        <th style="width: 15%">模板分类</th>
                        <th style="width: 15%">模板名称</th>
                        <th>模板配置消息</th>
                        <th>示例消息</th>
                        <th style="width: 5%">当前模板</th>
                        <th style="width: 5%">是否启用</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,key) in Get_moban_list_list" :key="key" :class="[item.check ? 'active':'']"
                        @click="clickrow(key)">
                        <td>
                            <a-checkbox v-model="item.check"></a-checkbox>
                        </td>
                        <td>{{item.notice_type_name}}</td>
                        <td>{{item.moban_name}}</td>
                        <td>{{item.moban_content}}</td>
                        <td>{{item.moban_show}}</td>
                        <td>{{item.touser === 1 ? '是':'否'}}</td>
                        <td>
                            <template v-if="item.touser === 1">
                                <a-switch v-model="item.status" @change="onChangeStatus(item)"/>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </a-col>
        </a-row>

        <div>
            <NewItem :edit_infoEditItem="edit_infoEditItem" :close="onCloseDrawerNewItem"
                     :visible="drawer_visibleNewItem"></NewItem>
        </div>
        <!--        <div>-->
        <!--            <EditItem :edit_infoEditItem="edit_infoEditItem" :edit_timeEditItem="edit_timeEditItem" :close="onCloseDrawerEditItem" :visible="drawer_visibleEditItem"></EditItem>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import NewItem from './NewItem/NewItem.vue';
    import EditItem from "./EditItem/EditItem";

    export default {
        name: "sampletype",
        components: {NewItem, EditItem},
        props: ["reload", "deep_info"],
        data() {
            return {
                all_active: false,
                info: [],
                formSearch: {
                    name: '',
                    status: '',
                },
                value: '',
                Moban_list_list: [],
                Get_moban_list_list: [],
                page: 1,
                count: 0,
                pagesize: 0,
                page_show: false,


                edit_timeNewItem: [],
                edit_infoNewItem: false,
                drawer_visibleNewItem: false,

                edit_timeEditItem: [],
                edit_infoEditItem: false,
                drawer_visibleEditItem: false,

                notice_type_id: ''
            }
        },
        watch: {},
        mounted() {
            this.Get_moban_list()
        },
        methods: {
            delClick() {
                let id = 0
                let count = 0
                let info = 0
                this.Get_moban_list_list.map((item, key) => {
                    if (item.check) {
                        id = item.id
                        count++
                        info = item
                    }
                })
                if (!id) return layer.msg('请选择一个消息模板')
                if (count !== 1) return layer.msg('每次只选择一个消息模板进行删除')
                if (info.touser === '2') return layer.msg('消息模板正在使用中不能删除！')
                this.$sa0.post({
                    url: this.$api('Del_moban'),
                    data: {
                        moban_id: info.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_moban_list()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },

                    })
                })
            },

            setThis() {
                let moban_id = 0
                let notice_type_id = 0
                let touser = 0
                let count = 0
                this.Get_moban_list_list.map((item, key) => {
                    if (item.check) {
                        // this.info = item;
                        moban_id = item.id
                        notice_type_id = item.notice_type_id
                        touser = item.touser
                        count++
                    }
                })
                if (touser === 1) return layer.msg('此模板已是当前模板，请点开分类选择其它模板进行设置')
                if (!moban_id) return layer.msg('请选择一个模板')
                if (count !== 1) return layer.msg('只能选择一个模板设为默认模板')
                let data = {
                    moban_id: moban_id,
                    notice_type_id: notice_type_id,
                }
                console.log(moban_id),
                    this.$sa0.post({
                        url: this.$api('Set_moban'),
                        data: data,
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (res) => {
                                layer.msg('已设置为默认模板')
                                this.Get_moban_list()
                            },
                            error: (res) => {
                                layer.msg(res.message)
                            }
                        })
                    })
            },
            Set_notice_type(item) {
                let data = {
                    status: item.status ? 1 : 2,
                    notice_type_id: item.notice_type_id,
                }
                this.$sa0.post({
                    url: this.$api('Set_notice_type'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已保存')
                            // this.Get_moban_list()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            clickrow(key) {
                this.$set(this.Get_moban_list_list[key], 'check', !this.Get_moban_list_list[key].check)
                this.itemActiveChange()
            },
            itemActiveChange() {
                let do_ = true
                this.Get_moban_list_list.map((item, key) => {
                    if (!item.check) do_ = false
                })
                this.all_active = do_
            },
            allChange(e) {
                this.Get_moban_list_list.map((item, key) => {
                    this.$set(this.Get_moban_list_list[key], 'active', this.all_active)
                })
            },

            Get_moban_list() {
                console.log('this.deep_info Get_moban_list', this.deep_info)
                if (this.deep_info) {
                    this.notice_type_id = this.deep_info.id === 'all' ? '' : this.deep_info.id
                } else {
                    this.notice_type_id = ''
                }
                console.log('this.notice_type_id', this.notice_type_id)
                this.$sa0.post({
                    url: this.$api('Get_moban_list'),
                    data: {
                        notice_type_id: this.notice_type_id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            if (this.notice_type_id === '') {
                                //console.log("6666666666666666666666666666666666666666666666666666666666666")
                                this.Get_moban_list_list = response.data.moban.filter(item => item.touser === 1).map(i => {
                                    i.status = i.status === 1
                                    return i
                                })
                            } else {
                                this.Get_moban_list_list = response.data.moban.map((item) => {
                                    item.status = item.status === 1
                                    item.check = false
                                    return item;
                                });
                            }

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 打开抽屉时的 新建
            showDrawerNewItem(item) {
                this.edit_infoEditItem = item
                this.drawer_visibleNewItem = true;
            },
            // 定义 关闭抽屉时的
            onCloseDrawerNewItem() {
                this.edit_infoNewItem = false
                this.drawer_visibleNewItem = false;
                this.Get_moban_list()
            },
            // 定义 打开抽屉时的 编辑
            showDrawerEditItem() {
                let muban_info = []
                let count = 0
                this.Get_moban_list_list.map((item, key) => {
                    if (item.check) {
                        muban_info = item;
                        count++
                    }
                })
                if (count === 0) return layer.msg('请选择一个模板进行编辑')
                if (count !== 1) return layer.msg('只能选择一个模板进行编辑')
                this.edit_infoEditItem = muban_info;

                // this.drawer_visibleEditItem = true;
                this.drawer_visibleNewItem = true;
            },
            // 定义 关闭抽屉时的
            onCloseDrawerEditItem() {
                this.edit_infoEditItem = false
                this.drawer_visibleEditItem = false;
            },

            handleChangeDefault(id, notice_type_id) {
                this.setThis(id, notice_type_id)
            },
            onChangeStatus(item) {
                this.Set_notice_type(item)
            },
        }
    }
</script>

<style scoped>
    .btner {
        width: 100%;
        background: #f9f9f9;
        padding: 5px 10px;
        font-size: 12px;
        color: #333;
        border: 1px solid #eeeeee;
        margin-bottom: 10px;
    }

    .active {
        background: #ebf6ff;
    }

    .btnerlist {
        cursor: pointer;
        border-right: 1px solid #eeeeee;
        height: 26px;
        line-height: 26px;
        padding: 0 6px;
        position: relative;
    }

    .btnerlist :last-child(1) {
        border: none
    }

    .btnerlist i {
        color: #999999;
        font-size: 14px;
        vertical-align: bottom;
    }

    .btnerlist:hover {
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;

    }

    .btnerlist:hover i, .btnerlist:hover.fcadd {
        color: #ffffff !important
    }

    .isthis {
        background: #1592fe;
        color: #ffffff;
        border-radius: 4px;
    }

    .isthis i {
        color: #ffffff
    }

    .layui-table th, .layui-table td {
        text-align: left !important;
    }
</style>

