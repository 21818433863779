<template>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <!--    :title="`${edit_infoNewItem.id===0?'新建':'维护'}检测项目`"-->
    <a-drawer title="编辑消息模板`" width="96%" placement="right" :closable="true" :visible="visible" @close="onClose">
        <div>
            <a-row>
                <a-col :span="13" style="border: solid 1px #efefef; padding: 15px">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>时间</th>
                            <th>任务信息</th>
                            <th>样品信息</th>
                            <th>检验信息</th>
                            <th>校验审核信息</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <p v-for="(item,key) in Get_tag_info_list.datatime">
                                    <a-button @click="addToContent(item)">{{ item.tag_content }}</a-button>
                                </p>
                            <td>
                                <p v-for="(item,key) in Get_tag_info_list.taskinfo">
                                    <a-button @click="addToContent(item)">{{ item.tag_content }}</a-button>
                                </p>
                            </td>
                            <td>
                                <p v-for="(item,key) in Get_tag_info_list.sampleinfo">
                                    <a-button @click="addToContent(item)">{{ item.tag_content }}</a-button>
                                </p>
                            </td>
                            <td>
                                <p v-for="(item,key) in Get_tag_info_list.checkinfo">
                                    <a-button @click="addToContent(item)">{{ item.tag_content }}</a-button>
                                </p>
                            </td>
                            <td>
                                <p v-for="(item,key) in Get_tag_info_list.examineinfo">
                                    <a-button @click="addToContent(item)">{{ item.tag_content }}</a-button>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </a-col>

                <a-col :span="10" :offset="1" style="border: solid 1px #efefef; padding: 15px">
                    <a-form-model v-model="formCreat">
                        <a-form-model-item label="模板类型" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">

                            <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }"
                                        v-model="formCreat.notice_type_id"
                                        :options="Get_notice_type_list" placeholder="请选择"/>

                        </a-form-model-item>
                        <a-form-model-item label="模板名称" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
                            <a-input placeholder="请输入模板名称" v-model="formCreat.moban_name"/>
                        </a-form-model-item>
                        <a-form-model-item label="模板内容" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
                            <a-input type="textarea" rows="5" v-model="formCreat.moban_content"/>
                        </a-form-model-item>
                        <a-form-model-item label="实时预览" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
                            <a-input type="textarea" rows="5" v-model="formCreat.moban_show"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="" :label-col="{ span: 4 }" :wrapper-col="{ span: 10, offset: 3 }">
                            <a-button type="primary" @click="doCreat()">保存</a-button>
                        </a-form-model-item>
                    </a-form-model>
                </a-col>
            </a-row>
        </div>
    </a-drawer>
</template>

<script>
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_infoEditItem'],

        data() {
            return {
                formCreat: {
                    moban_name: '',
                    moban_content: '',
                    moban_show: '',
                    notice_type_id: [],
                },
                Get_tag_info_list: [],
                Get_notice_type_list: [],
            }
        },
        watch: {},
        mounted() {
            this.Get_tag_info()
            this.Get_notice_type()
            //this.formCreat = JSON.parse(JSON.stringify(this.edit_infoNewItem))
            console.log("=========================================================================")
            console.log(this.iNumber)
        },
        methods: {
            addToContent(item) {
                this.formCreat.moban_content += item.tag_content
                this.replaceMuban(this.formCreat.moban_content)
            },
            replaceMuban(moban) {
                for (let index in this.Get_tag_info_list) {
                    this.Get_tag_info_list[index].map(item => {
                        if (moban.indexOf(item.tag_content) >= 0) {
                            moban = moban.replaceAll(item.tag_content, item.text)
                        }
                    })
                    this.formCreat.moban_show = moban
                }
            },
            doCreat() {
                if(this.iNumber === 0 ) {
                    console.log("新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建新建")
                    if (this.formCreat.notice_type_id.length < 1) {
                        layer.msg('请选择消息类型')
                        return
                    }
                    if (this.formCreat.moban_name.length === 0) {
                        layer.msg('请输入模板名字')
                        return
                    }
                    if (this.formCreat.moban_content.length === 0) {
                        layer.msg('请输入模板内容')
                        return
                    }
                    let data = {
                        moban_content: this.formCreat.moban_content,
                        moban_name: this.formCreat.moban_name,
                        moban_show: this.formCreat.moban_show,
                        notice_type_ids: this.formCreat.notice_type_id,
                        notice_type_id: this.formCreat.notice_type_id.pop()
                    }
                    this.$sa0.post({
                        url: this.$api('Create_moban'),
                        data: data,
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (res) => {
                                layer.msg('已保存')
                                this.close()
                            },
                            error: (res) => {
                                layer.msg(res.message)
                            }
                        })
                    })
                }else {
                    console.log("编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑编辑")

                    let data = {
                        moban_content: this.formCreat.moban_content,
                        moban_name: this.formCreat.moban_name,
                        moban_show: this.formCreat.moban_show,
                        notice_type_ids: this.formCreat.notice_type_id,
                        notice_type_id: this.formCreat.notice_type_id.pop(),
                        moban_id:this.formCreat.moban_id
                    }
                    this.$sa0.post({
                        url: this.$api('Edit_moban'),
                        data: data,
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (res) => {
                                layer.msg('已保存')
                                this.close()
                            },
                            error: (res) => {
                                layer.msg(res.message)
                            }
                        })
                    })

                }
            },

            Get_notice_type() {
                this.$sa0.post({
                    url: this.$api('Get_notice_type'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_notice_type_list = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Get_tag_info() {
                this.$sa0.post({
                    url: this.$api('Get_tag_info'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_tag_info_list = response.data.list;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>

<style scoped>
    textarea.ant-input {
        height: auto !important;
    }
</style>
